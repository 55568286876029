.card:hover {
    -webkit-box-shadow: 1px 1px 20px 1px #24b47e;
    box-shadow: 1px 1px 20px 1px #24b47e;
    cursor: pointer;
    }

.cart-changed {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
    
    /* When the animation is finished, start again */
    animation-iteration-count: 1;
    }

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg) scale(1.0); }
    10% { transform: translate(-1px, -2px) rotate(-1deg) scale(1.1) ;}
    20% { transform: translate(-3px, 0px) rotate(1deg) scale(1.2) ; }
    30% { transform: translate(3px, 2px) rotate(0deg) scale(1.3); }
    40% { transform: translate(1px, -1px) rotate(1deg) scale(1.4); }
    50% { transform: translate(-1px, 2px) rotate(-1deg) scale(1.4); }
    60% { transform: translate(-3px, 1px) rotate(0deg) scale(1.4); }
    70% { transform: translate(3px, 1px) rotate(-1deg) scale(1.3); }
    80% { transform: translate(-1px, -1px) rotate(1deg) scale(1.2); }
    90% { transform: translate(1px, 2px) rotate(0deg) scale(1.1); }
    100% { transform: translate(1px, -2px) rotate(-1deg) scale(1.0); }
  }

  .cart{
    animation: shake 0.5s;
    
    /* When the animation is finished, start again */
    animation-iteration-count: 1;
  }
 

  body.modal-open > :not(.modal) {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    min-width: 400px;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
    border-radius: 4px;
}

.styled-table thead tr {
    background-color: #7795f8;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #7795f8;
}
